import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'react-autocomplete';
import {AddressLookupApi} from '../../../api/externalServices.js';
import * as ImagesPath from '../../../config/imagesPath';

export class AddressInput  extends React.Component{

  constructor(props, context) {
      super(props, context);

      this.onChange = this.props.onChange;
      this.onServiceError = this.props.onServiceError;

      this.getAddresses = this.getAddresses.bind(this);
      this.updateAddress =  this.updateAddress.bind(this);

      this.state = {
         addressesList: [],
         addressesPreviousList: [],
         addressLoading:false,
         prevAddressLoading:false
      };
  }

    updateAddress(event) {
      let target = event.target;
      let newValue = target.type === 'checkbox' ? target.checked : target.value;
      let propertyName = target.name;

      this.onChange(
        {
            target : {
                name: propertyName,
                value: newValue
            }
        });
    }

   getAddresses(propertyName, request, bindResultToCtrl) {
           if (request) {
               AddressLookupApi.getAddresses(request, this.props.csrfToken).then(addresses => {
                   const parseAddress = JSON.parse(addresses);
                   bindResultToCtrl((parseAddress && parseAddress['Result']) ? parseAddress.Result : []);
               })
                   .catch(response => {
                       this.onServiceError({
                           target: {
                               name: propertyName
                           }
                       });
                   }).then(addresses => {
                       this.setState({ addressLoading: false, prevAddressLoading: false })
                   });
           }
           else {
               this.setState({ addressLoading: false, prevAddressLoading: false });
           }
   }


    render() {
        let adressInputCls = this.state.addressLoading ?'form-control loading' :'form-control';
        let prevAddressCls = this.state.prevAddressLoading? 'form-control  xs-mt-10 loading': 'form-control  xs-mt-10';
        let prevAddressLabelCls = "control-label col-sm-4 xs-mt-10";
        adressInputCls += this.props.addressError ? " has-error" : "";
        prevAddressCls += this.props.prevAddressError ? " has-error" : "";

        if (!this.props.hasLivedLessThan3Years) {
          prevAddressCls += " hidden";
          prevAddressLabelCls += " hidden";

        }

        let groupClass = "form-group address-container";
         if (this.props.addressError || this.props.prevAddressError) {
           groupClass+= ' has-error';
         }

         let {toolTip} = this.props;

        return (        
          <>
            <div className={this.props.orderClass ? ("col-lg-12 col-12 order-" + this.props.orderClass) : "col-lg-12 col-12"}>
              <div className="form-label-group ">
                 <label htmlFor="address">Current Address*</label>
                 <br/>
                <Autocomplete
                    value={this.props.address}
                    inputProps={{name: this.props.addressname, id: this.props.addressname, className: adressInputCls, placeholder: this.props.placeholder}}
                    wrapperStyle={{}}
                    menuStyle={{padding:0, background: 'rgba(255, 255, 255, 0.9)',
                              borderRadius: '3px',  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)', overflow: 'auto',
                              maxHeight: '300px' }}
                    items={this.state.addressesList}
                    getItemValue={(item) => item}
                    onChange={(event, address) => {
                      this.setState({ addressLoading: true })
                      this.updateAddress(event);
                      this.getAddresses(this.props.addressname, address, (items) => {
                      this.setState({ addressesList: items, addressLoading: false })
                    })
                  }}
                  onSelect={(address, item) => {
                                this.onChange( {target : {name: this.props.addressname,value: address} });
                                this.setState({ addressesList: [ item ] })
                              }}
                  renderItem={(item, isHighlighted) => (
                              <div className={isHighlighted ? 'highlighted-item' : 'menu-item'}>{item}</div>
                            )}
                />
                {/* <label for="address">Current Address*</label> */}
                {this.props.addressError && (
                    <div className="invalid-feedback text-danger form-error-msg">
                            {this.props.addressError}
                    </div>
                )}
              </div>
            </div>

            <div className={this.props.orderClass ? ("col-12 checkbox-field order-lg-" + (this.props.orderClass +1) + " order-" + (this.props.orderClass +1)) : "col-12 checkbox-column checkbox-field"}>
              <div className="form-label-group form-checkbox  align-self-center address-input-n">
                <input name={this.props.hasLivedLessThan3YearsId} className="form-check-input" type="checkbox" value="" id={this.props.hasLivedLessThan3YearsId} checked={this.props.hasLivedLessThan3Years?'checked':''} onChange={this.updateAddress}/>
                <label className="form-check-label reg-title-small" htmlFor={this.props.hasLivedLessThan3YearsId}>
                  I have lived here for less than 3 yrs.
                </label>
                {toolTip && <a className="info-tooltip reg-lvl-3y" data-toggle="tooltip" data-placement="bottom" title={toolTip["helpText"]} data-title={toolTip["helpText"]}><img src={ImagesPath.ImgIconInfo} className="img-fluid info-icon" alt=""/></a>}
              </div>
              <div className="form-label-group check-field" >
                {this.props.hasLivedLessThan3Years && ( <><label for="old_address">Previous Address*</label><br/></>)}
                <Autocomplete
                    value={this.props.prevAddress}
                    inputProps={{name: this.props.prevAddressName, id: this.props.prevAddressName, className: prevAddressCls, placeholder: this.props.prevaddplaceholder}}
                    wrapperStyle={{}}
                    menuStyle={{padding:0, background: 'rgba(255, 255, 255, 0.9)',
                              borderRadius: '3px',  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)', overflow: 'auto',
                              maxHeight: '300px' }}
                    items={this.state.addressesPreviousList}
                    getItemValue={(item) => item}
                    onChange={(event, address) => {
                      this.setState({ prevAddressLoading: true })
                      this.updateAddress(event);
                      this.getAddresses(this.props.prevAddressName, address, (items) => {
                      this.setState({ addressesPreviousList: items, prevAddressLoading: false })
                    })
                    }}
                    onSelect={(address, item) => {
                      this.onChange( {target : {name: this.props.prevAddressName,value: address} });
                      this.setState({ addressesPreviousList: [ item ] })
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div className={isHighlighted ? 'highlighted-item' : 'menu-item'}>{item}</div>
                    )}
                />
                {/* <label for="old_address">Old address*</label> */}
                {(this.props.hasLivedLessThan3Years &&this.props.prevAddressError) && (
                  <div className=" invalid-feedback text-danger form-error-msg">
                          {this.props.prevAddressError}
                  </div>
                )}
              </div>
            </div>
          </>
      );
    }
};

 AddressInput.propTypes = {
  addressname: PropTypes.string.isRequired,
  prevAddressName: PropTypes.string.isRequired,
  prevAddress: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  hasLivedLessThan3Years: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelPreviousAddress: PropTypes.string.isRequired,
  hasLivedLessThan3YearsLabel:PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  prevaddplaceholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onServiceError: PropTypes.func.isRequired,
  addressError: PropTypes.string,
  prevAddressError: PropTypes.string,
  orderClass: PropTypes.number
};


export default AddressInput;
