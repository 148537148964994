import * as GlobalVariables from '../config/configureGlobalVariables';
import HelperApi from './HelperApi';

export class AddressLookupApi {
    static getAddresses(term, csrfToken) {
      let urlEncodeTerm = encodeURIComponent(term);
      let fullUrl = GlobalVariables.ADDRESS_SERVICE_URL + urlEncodeTerm;

      let headers = {};
      headers['Content-Type'] = 'application/json';
      headers['x-gcs-xsrf'] = csrfToken;
      let options = {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: headers
      };

      return fetch(fullUrl, options).then(response => {
          if (!response.ok) {
              return Promise.reject(response);
          } else {
              try {
                  return response.json();
              } catch (error) {
                  console.error('Error parsing JSON:', error);
                  return {};
              }
          }
          }).catch(function (error) {
              console.log('Request failed', error);
              throw (error);
          });
      }
  }

export class LodgeAComplaintApi {
    static sendLodgeDataThroughGcs(formData, token) {
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/lodge-a-complaint';
      let options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-recaptcha': token      
        },
        body: JSON.stringify(formData)
      };
  
      return fetch(fullUrl, options).then(response => {
            if (!response.ok) {
              return Promise.reject(response);
            }
            else{
              return response.ok;
            }
          }
        ).catch(function (error) {
          throw(error);
        });
    }
}

export class ContactUsApi {
  static sendDataToSalesforce(formData) {
    let options = {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept':'text/html'
      },
      body: HelperApi.encodeData(formData)
    };

    fetch(GlobalVariables.CONTACTUS_BASE_URL, options).then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      } else {
        return response;
      }
    }).catch(function (error) {
      throw(error);
    });
  }

  static sendDataThroughGcs(formData, token) {
    const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/contact-us';
    let options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-recaptcha': token      
      },
      body: JSON.stringify(formData)
    };

    return fetch(fullUrl, options).then(response => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          else{
            return response.ok;
          }
        }
      ).catch(function (error) {
        throw(error);
      });
  }
}